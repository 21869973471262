/* General */

:root {
  --main-blue: rgba(0, 110, 144, 1);
  --main-gray: rgba(229, 229, 229, 1);

  --main-orange: rgba(241, 143, 1, 1);
  background-color: var(--main-gray)
}

:root.signup {
  background-color: var(--main-gray);
}

/* Buttons */

.btn-visu {
  float: right;
  margin-top: 15px;
  font-size: 12px;
  border-color: var(--main-blue);
  color: var(--main-blue)
}

.btn-info {
  float: left;
  background-color: white;
  color: var(--main-blue);
  margin-top: 15px
}

.btn-slup {
  float: none;
  font-size: 15px;
  border-color: var(--main-orange);
  background-color: var(--main-orange);
  color: white;
  font-weight: bold;
}

.btn-home {
  float: none;
  font-size: 15px;
  border-color: var(--main-orange);
  background-color: var(--main-orange);
  color: white;
  font-weight: bold;
  margin: 30px 10px;
}

.btn-home-su,
.btn-nav {
  border-color: var(--main-orange);
  background-color: white;
  color: var(--main-orange);
}

.btn-nav1 {
  margin-right: 20px;
}

.btn-logout {
  float: left;
  font-size: 13px;
  border-color: var(--main-orange);
  background-color: white;
  color: var(--main-orange);
}

.btn:hover {
  background-color: var(--main-blue) !important;
  border-color: var(--main-blue) !important;
  border-color: white !important;
}

.btn-slup:hover,
.btn-home-li:hover,
.btn-home-su:hover,
.btn-nav:hover {
  background-color: rgb(255, 153, 0) !important;
  border-color: rgb(255, 153, 0) !important;
}

.btn:focus {
  border-color: var(--main-blue) !important;
  box-shadow: 0px 0px 8px var(--main-blue) !important;
}

.btn-slup:focus,
.btn-logout:focus,
.btn-nav:focus {
  border-color: var(--main-orange) !important;
  box-shadow: 0px 0px 8px var(--main-orange) !important;
}

/* Titles */

h1 {
  font-size: 50px;
  text-align: center;
}

p {
  text-align: center;
}

/* Home */

.home-container {
  background-color: var(--main-gray);
  /* color: var(--main-blue); */
  text-align: center;
  position: relative;
  margin-bottom: 100px;
}

.home-container h1 {
  padding: 70px 100px 30px;
  font-weight: bold;
}

.home-container p {
  width: 70%;
  margin-left: 15%;
}

.home-container .ext-link {
  color: var(--main-orange);
}

.home-container img {
  border-radius: 10px;
}

img {
  width: 80%;
  margin-bottom: 50px;
}

/* Signup and Login */

.slup-container {
  background-color: var(--main-gray);
  margin-bottom: 200px;
}

.slup-container h1 {
  padding: 70px;
  font-weight: bold;
  /* color:white; */
}

.slup-form {
  position: relative;
}

.form-floating label {
  margin-left: 35%;
}

.form-control {
  margin-left: 35%;
  width: 30%;
}

.form-control:focus {
  border-color: var(--main-orange) !important;
  box-shadow: 0px 0px 8px var(--main-orange) !important;
}

.btn-form-element {
  margin: 0;
  margin-top: 70px;
  position: absolute;
  /* top: 50%; */
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* Profile */

.profile-container {
  background-color: var(--main-gray);
}

.profile-container .table {
  margin-left: auto;
  margin-right: auto;
  width: 30%;
}

/* View */

.title {
  padding: 30px 0px 55px 0px;
}

#sub {
  vertical-align: sub;
}

.container-view {
  background-color: var(--main-gray);
  padding-bottom: 100px;
}

#flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-evenly;
  text-align: center;
  gap: 30px;
  /* padding: 10px 10px; */
}

#flex-container>div {
  /* flex-basis: 35%; */
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  width: 100%;
}

.flex-item>span {
  width: 100%;
}

.flex-item>div {
  padding: 20px;
  background-color: white;
  border-radius: 10px;
}

.flex-item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-evenly;
  text-align: center;
  gap: 30px;
  padding-bottom: 100px;
}

.view-canvas {
  width: 100%
}

.doughnut-chart {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.info-text {
  font-size: 13px;
  margin-top: 20px
}

/* Custom view */

.custom-container {
  background-color: var(--main-gray);
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.dropdown {
  position: relative;
}

.menu {
  position: absolute;

  list-style-type: none;
  margin: 5px 0;
  padding: 0;

  border: 1px solid grey;
  width: 150px;
}

.menu>li {
  margin: 0;

  background-color: white;
}

.menu>li:hover {
  background-color: lightgray;
}

.menu>li>button {
  width: 100%;
  height: 100%;
  text-align: left;

  background: none;
  color: inherit;
  border: none;
  padding: 5px;
  margin: 0;
  font: inherit;
  cursor: pointer;
}

.form-horizontal {
  display: flex;
  flex-direction: column;
  /* align-items: center; */

}

.modal-background {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-container {
  display: flex;
  flex-direction: column;
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 70%;
  border-radius: 8px;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 24px;
  font-weight: bold;
}

.customViews {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-evenly;
  text-align: center;
  width: 100%;
  padding: 10px 10px;
}

.customView {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: white;
  justify-content: space-between;
  margin: 15px;
  padding: 20px;
  height: 300px;
  width: 20%;
  border: 1px solid black;
  border-radius: 8px;
}

.customViewButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.customViewButtons>.btn-view {
  width: 50%;
}

.customViewButtons>.btn-delete {
  width: 30%;
  display: flex;
  align-content: center;
  justify-content: center;
}

@media screen and (max-width: 992px) {

  h1 {
    font-size: 40px
  }

  /* Home */
  .home-container h2 {
    font-size: 27px
  }

  .home-container p,
  a {
    font-size: 14px
  }

  /* Views */
  .view-canvas {
    width: 100%;
  }

  /* Profile page*/

  table td {
    font-size: 15px;
  }

  .customViewButtons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 70px;
  }

  .customViewButtons>.btn-view {
    width: 70%;
  }

  .customViewButtons>.btn-delete {
    width: 40%;
  }

}

@media screen and (max-width: 768px) {

  h1 {
    font-size: 35px
  }

  /* Home */
  .home-container h2 {
    font-size: 25px
  }

  .home-container p,
  a {
    font-size: 13px
  }

  .btn-home,
  .btn-nav1,
  .btn-info {
    font-size: 13px;
  }

  /* Views */
  .view-canvas {
    width: 100%;
  }

  /* Profile page*/

  table td {
    font-size: 13px;
  }

  .customView {
    width: 40%;
  }

}

@media screen and (max-width: 600px) {

  h1 {
    font-size: 30px
  }

  /* Home */
  .home-container h2 {
    font-size: 22px
  }

  .btn-home,
  .btn-nav1,
  .btn-info {
    font-size: 12px;
  }

  /* Profile page*/

  table td {
    font-size: 12px;
  }

  .customView {
    width: 60%;
  }

}